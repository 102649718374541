<template>
    <div>
        <div class="container-item" :style="'border-color:' + borderColorClass">
            <Molded>
                <b-row>
                    <b-col sm="8">
                        <span class="link" @click="update(item)">
                            <i v-if="item.barCode" class="fas fa-box"></i>
                            <i v-else class="fas fa-toolbox"></i>
                            {{ item.productFullName }}
                        </span>
                        <span class="title" v-if="item.barCode">
                            - Nº Patrimônio {{ item.barCode }}
                        </span>
                        <span class="title" v-else>
                            - Quantidade {{ item.quantity }}
                        </span>
                        <div class="text-description title">
                            <small>{{ item.description }}</small>
                        </div>
                        <div>
                            <span class="title"> Início: {{ item.dateStartStr }} - Retorno: {{ item.dateEndStr }}</span>
                        </div>
                        <div>
                            <span class="title">
                                Valor: {{ item.totalValue | currency }}
                            </span>
                        </div>
                    </b-col>
                    <b-col sm="3">
                        <div class="div-toggle">
                            <Toggle title="Retornado" :color="'#009183'" v-model="item.returned" :changed="changed" />
                        </div>
                    </b-col>
                    <b-col sm="1">
                        <div class="text-right div-btn-remove">
                            <Button :_key="item.id" type="danger" classIcon="fa-solid fa-trash" size="small"
                                :clicked="remove" :params="item" />
                        </div>
                    </b-col>
                </b-row>
            </Molded>
        </div>
    </div>
</template>

<script>

import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Toggle from "@nixweb/nixloc-ui/src/component/forms/Toggle";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapActions, mapMutations } from "vuex";

export default {
    name: "ItemsOrderServiceItem",
    props: ["item"],
    components: {
        Molded,
        Toggle,
        Button
    },
    data() {
        return {
            urlDeleteApi: "/api/v1/maintenance/items-os/delete",
            urlIsReturnedApi: "/api/v1/maintenance/items-os/is-returned",
        }
    },
    computed: {
        borderColorClass() {
            return this.item.returned ? 'green' : '#FF8A1B';
        }
    },
    methods: {
        ...mapActions("generic", ["putApi", "deleteAllApi"]),
        ...mapMutations("generic", ["addEvent"]),
        changed() {
            let params = { url: this.urlIsReturnedApi, obj: this.item, notNotifyToast: true };
            this.putApi(params).then((response) => {
                if (!response.success) this.item.returned = true;

            });
        },
        update(item) {
            this.addEvent({ name: "updateItemOrderService", data: item });
        },
        remove(item) {
            let params = {
                url: this.urlDeleteApi,
                selected: [item.id],
            };
            this.deleteAllApi(params).then((response) => {
                if (response.success) {
                    this.addEvent({ name: "saveItemsOrderService" });
                }
            });
        },
    }
}
</script>
<style scoped>
.container-item {
    margin-top: 25px;
    border-left: 2px solid;
    border-color: red;
    border-radius: 10px;
}

.link {
    color: #3f529b;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}

.link:hover {
    text-decoration: underline;
}

.div-toggle {
    margin-top: 20px;
}

.div-btn-remove {
    margin-top: 20px;
}

.text-description {
    max-width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>