<template>
  <div class="side-by-side">
    <Dropdown title="Opções" type="primary" size="small" classIcon="fa-solid fa-chevron-down" right="50"
      :items="optionsConditional" />

    <PrintPreview v-if="printRent" module="Maintenance" :orderServiceId="id" :onHideModal="closeModal" />

    <Modal :title="modalOptions.title" :width="modalOptions.width" v-if="showModal('optionsOs')"
      :onHideModal="closeModal">
      <div class="text-center">
        <Button _key="btnUpdateStatus" :title="selectedStatus.title" :type="selectedStatus.typeButton" size="medium"
          :clicked="updateStatus" />
      </div>
    </Modal>

  </div>
</template>
<script>


import Dropdown from "@nixweb/nixloc-ui/src/component/forms/Dropdown.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import PrintPreview from '../../adm/document/PrintPreview.vue';

import { mapGetters, mapMutations, mapState, mapActions } from "vuex";

export default {
  name: "DropdownOptionsRent",
  components: {
    Dropdown,
    Modal,
    Select,
    Button,
    PrintPreview
  },
  props: {
    orderService: Object
  },
  data() {
    return {
      id: this.$route.params.id,
      printRent: false,
      urlUpdate: "/api/v1/maintenance/order-service/update-status-order-service",
      modalOptions: {
        name: "",
        title: "",
        width: 0,
      },
      options: [
        {
          title: "Compartilhar",
          showByStatus: [1, 2, 3],
          classIcon: "fa-sharp fa-regular fa-share-nodes",
          eventName: "printRent",
          hr: true,
        },
        {
          title: "Aprovar",
          showByStatus: [1, 3],
          classIcon: "fa-solid fa-circle-check",
          eventName: "acceptOs",
          style: "color:#38A169;",
        },
        {
          title: "Orçamento",
          showByStatus: [2, 3],
          classIcon: "fa-sharp fa-solid fa-file-invoice",
          eventName: "progressOs",
          style: "color:#4BB4E2;",
        },
        {
          title: "Reprovar",
          showByStatus: [1, 2],
          classIcon: "fa-solid fa-do-not-enter",
          eventName: "rejectOs",
          style: "color:red;",
        },
      ],
      selectedStatus: {
        title: "",
        any: "0",
        typeButton: "",
      }
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
    ...mapState("user", ["userLogged"]),
    ...mapGetters("user", ["hasRule", "hasPermission"]),
    optionsConditional() {
      let options = [];
      let status = this.orderService.status;

      this.options.forEach((item) => {
        if (item.showByStatus.includes(status)) options.push(item);
      });
      return options;
    },
  },
  methods: {
    ...mapMutations("generic", ["openModal", "addEvent", "hideModal", "removeLoading"]),
    ...mapActions("generic", ["putApi"]),
    updateStatus() {
      let params = { url: this.urlUpdate, obj: { id: this.orderService.id, any: this.selectedStatus.any } };
      this.putApi(params).then((response) => {
        if (response.success) {
          this.addEvent({ name: "updatedStatusOs" });
          this.hideModal();
        }
        this.removeLoading(["btnUpdateStatus"]);
      });
    },
    closeModal() {
      this.modalOptions = { name: "", title: "", width: 0 };
      this.printRent = false;
      this.hideModal();
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "printRent") {
          this.printRent = true;
        }

        if (event.name == "acceptOs") {
          this.modalOptions = { name: "acceptOs", title: "Mudar para Aprovada?", width: 500, };
          this.selectedStatus.title = "Aprovar";
          this.selectedStatus.typeButton = "success";
          this.selectedStatus.any = "2";
          this.openModal("optionsOs");
        }

        if (event.name == "progressOs") {
          this.modalOptions = { name: "progressOs", title: "Mudar para Orçamento?", width: 500, };
          this.selectedStatus.title = "Orçamento";
          this.selectedStatus.typeButton = "info";
          this.selectedStatus.any = "1";
          this.openModal("optionsOs");
        }

        if (event.name == "rejectOs") {
          this.modalOptions = { name: "rejectOs", title: "Mudar para Reprovada?", width: 500, };
          this.selectedStatus.title = "Reprovar";
          this.selectedStatus.typeButton = "danger";
          this.selectedStatus.any = "3";
          this.openModal("optionsOs");
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.select-document {
  min-height: 100px;
}
</style>