<template>
    <div>
        <Alert type="info" v-if="itemsOrderService.id">
            Produto com estoque em manutenção, não é permitido alterar alguns campos!
        </Alert>
        <b-row v-show="!itemsOrderService.id">
            <b-col xs="12" sm="12" md="12" lg="7" xl="7">
                <RadioGroup :formName="formName" :options="[
                    { text: 'Patrimoniado', value: 1 },
                    { text: 'Não patrimoniado', value: 2 },
                ]" v-model="itemsOrderService.type" />
            </b-col>
        </b-row>
        <b-row v-show="!isPatrimony">
            <b-col xs="12" sm="12" md="12" lg="5" xl="5">
                <Select title="Tipo" field="type" :formName="formName" :required="!isPatrimony" :markFormDirty="false"
                    url="/api/v1/stock/type-product/select-all" :disabled="disabled" :showNewRegister="false"
                    v-model="itemsOrderService.typeProduct" :changed="cleanProduct" />
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="7" xl="7">
                <Select title="Produto" field="product" :formName="formName" :required="!isPatrimony"
                    :markFormDirty="false" url="/api/v1/stock/product/select-all-maintenance" :disabled="disabled"
                    :propsParams="{ any: itemsOrderService.typeProduct.id }" :showNewRegister="false"
                    v-model="itemsOrderService.product" />
            </b-col>
        </b-row>
        <b-row v-show="isPatrimony">
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <Select title="Patrimônio" :required="isPatrimony" field="patrimony" :formName="formName"
                    :disabled="disabled" :markFormDirty="false" url="/api/v1/stock/patrimony/select-all-with-product"
                    :showNewRegister="false" :changed="changePatrimony" v-model="itemsOrderService.patrimony" />
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="12">
                <div>
                    <TextArea title="Descrição" field="description" :required="false" :markFormDirty="false"
                        :maxLength="2000" v-model="itemsOrderService.description" />
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="3">
                <DateTime title="Inicio" field="startDateHour" format="DD/MM/YYYY" type="date" placeholder
                    :formName="formName" :markFormDirty="false" :required="true"
                    v-model="itemsOrderService.dateStart" />
            </b-col>
            <b-col sm="3">
                <DateTime title="Fim (Previsão)" field="endDateHour" format="DD/MM/YYYY" type="date" placeholder
                    :formName="formName" :markFormDirty="false" :required="true" v-model="itemsOrderService.dateEnd" />
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="2" v-if="!isPatrimony">
                <InputNumber title="Quantidade" field="value" :formName="formName" :required="false"
                    :markFormDirty="false" :maxLength="10" :precision="2" type="float"
                    v-model="itemsOrderService.quantity" />
            </b-col>
            <b-col sm="2">
                <InputDecimal title="Valor" field="value" :formName="formName" :required="false" :markFormDirty="false"
                    :maxLength="10" :precision="2" type="float" v-model="itemsOrderService.value" />
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="12">
                <div class="div-btn text-right">
                    <Button _key="btnSaveItemsOrderService" type="success" title="Salvar"
                        :disabled="!isFormValid(formName)" classIcon="fas fa-save" size="medium" :clicked="save" />
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup";
import TextArea from "@nixweb/nixloc-ui/src/component/forms/TextArea";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";

import ItemsOrderService from "@/components/modules/maintenance/items-order-service/ItemsOrderService.js";

import { mapGetters, mapActions, mapMutations, mapState } from "vuex";

export default {
    name: "ItemsOrderServiceCreateUpdate",
    props: {
        orderServiceId: String,
    },
    components: {
        Button,
        DateTime,
        InputText,
        InputDecimal,
        Molded,
        Select,
        RadioGroup,
        TextArea,
        InputNumber,
        Alert
    },
    data() {
        return {
            formName: "itemsOrderServiceCreateUpdate",
            itemsOrderService: new ItemsOrderService(),
            urlCreate: "/api/v1/maintenance/items-os/create",
            urlUpdate: "/api/v1/maintenance/items-os/update",
            urlGetById: "/api/v1/maintenance/items-os/get-by-id",
        };
    },
    computed: {
        ...mapGetters("validation", ["isFormValid"]),
        ...mapGetters("generic", ["event"]),
        ...mapState("validation", ["validations"]),
        isPatrimony() {
            if (this.itemsOrderService.type == 1) return true;
            return false;
        },
        disabled() {
            if (this.itemsOrderService.id != '') return true;
            return false;
        }
    },
    methods: {
        ...mapActions("generic", ["postApi", "putApi"]),
        ...mapMutations("generic", ["addEvent", "removeLoading", "hideModal"]),
        ...mapMutations("validation", ["resetValidation", "removeValidation"]),
        save(isOpticalReader) {
            this.itemsOrderService.orderServiceId = this.orderServiceId;
            if (this.itemsOrderService.id) {
                let params = { url: this.urlUpdate, obj: this.itemsOrderService };
                this.putApi(params).then((response) => {
                    if (response.success) {
                        this.savedSuccess(isOpticalReader);
                    }
                    this.removeLoading(["btnSaveItemsOrderService"]);
                });
            } else {
                let params = { url: this.urlCreate, obj: this.itemsOrderService };
                this.postApi(params).then((response) => {
                    if (response.success) {
                        this.savedSuccess(isOpticalReader);
                    }
                    this.removeLoading(["btnSaveItemsOrderService"]);
                });
            }
        },
        saveByBarCode(data) {
            const itemsOrderService = {
                id: "",
                type: data.product.patrimonyId ? 1 : 2,
                patrimony: { id: data.product.patrimonyId, content: data.product.patrimonyId },
                typeProduct: { id: "", content: "" },
                product: { id: data.product.productId, content: data.product.productId },
                quantity: 1,
                description: "",
                dateStart: data.dateStart,
                dateEnd: data.dateEnd,

            };
            this.itemsOrderService = itemsOrderService;
            this.save(true);
        },
        savedSuccess(isOpticalReader) {
            this.addEvent({ name: "saveItemsOrderService" });
            this.addEvent({ name: "updateTotalizationOrderService" });
            if (!isOpticalReader)
                this.hideModal();
        },
        cleanProduct() {
            this.itemsOrderService.patrimony = { id: "", content: "" };
        },
        changePatrimony() {
            this.itemsOrderService.product = { id: this.itemsOrderService.patrimony.any.id, content: "" };
        }
    },
    watch: {
        event: {
            handler(event) {

                if (event.name == "createItemsOrderService") {
                    this.itemsOrderService = new ItemsOrderService();
                    this.resetValidation(this.formName);
                }

                if (event.name == "updateItemOrderService") {
                    this.itemsOrderService.update(event.data);
                }

                if (event.name == "executedSearchByBarcodeOrderService") {
                    this.saveByBarCode(event.data);
                }
            },
            deep: true,
        },
        "itemsOrderService.type": {
            handler(type) {
                this.cleanProduct();

                if (type == 2)
                    this.removeValidation({ key: "patrimony&itemsOrderServiceCreateUpdate" });
            },
            deep: true,
        },
    },
}

</script>