<template>
    <div>
        <div v-if="id">
            <div>
                <Button _key="btnOrderServiceCreate" title="Ordem de Serviço" classIcon="fas fa-plus-circle"
                    type="primary" size="small" :clicked="create" />

                <DropdownOptionsOs :orderService="orderService" />
            </div>
            <br>
            <b-row>
                <b-col sm="5">
                    <Badge :title="'Nº ' + orderService.number.toString()" type="primary" size="medium" />
                    <Badge :title="status.title" :type="status.type" size="small" />
                </b-col>
            </b-row>
            <br />
            <div>
                <OrderServiceTotalization :orderServiceId="id" />
            </div>
        </div>
        <Molded>
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                    <InputText title="Identificação" field="identification" :formName="formName" :required="true"
                        :maxLength="100" v-model="orderService.identification" />
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                    <CustomerWithCreate :formName="formName" :required="false" v-model="orderService.customer" />
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                    <Select title="Fornecedor" field="type" :formName="formName" url="/api/v1/crm/customer/select-all"
                        :propsParams="{ isCustomer: false, isSupplier: true }" :showNewRegister="false"
                        v-model="orderService.supplier">
                    </Select>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="7" xl="7">
                    <RadioGroup title="Tipo de Manutenção" field="typeMaintenance" :formName="formName" :options="[
                        { text: 'Corretiva', value: 1 },
                        { text: 'Preventiva', value: 2 },
                    ]" v-model="orderService.typeMaintenance" />
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="12">
                    <InputText title="Observações" field="observation" :maxLength="2000" :required="false"
                        v-model="orderService.description" />
                </b-col>
            </b-row>
        </Molded>
        <br />
        <div v-if="id">
            <b-tabs>
                <b-tab title="Produto">
                    <ItemsOrderServiceList :orderServiceId="id" />
                </b-tab>
                <b-tab title="Anexo">
                    <FileManager :showInModal="true" source="maintenance" :genericId="id" />
                </b-tab>
            </b-tabs>

        </div>
    </div>
</template>

<script>

import Badge from "@nixweb/nixloc-ui/src/component/layout/Badge.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded.vue";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup";
import FileManager from "@nixweb/nixloc-ui/src/component/shared/file-manager/FileManager";
import DropdownOptionsOs from './DropdownOptionsOs.vue'

import OrderService from "@/components/modules/maintenance/order-service/OrderService.js";
import CustomerWithCreate from '../../rental/shared/CustomerWithCreate.vue';
import ItemsOrderServiceList from '../items-order-service/ItemsOrderServiceList.vue'
import OrderServiceTotalization from './OrderServiceTotalization.vue'

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
    name: "OrderServiceCreateUpdate",
    components: {
        Button,
        Badge,
        InputText,
        Molded,
        Select,
        RadioGroup,
        CustomerWithCreate,
        ItemsOrderServiceList,
        OrderServiceTotalization,
        FileManager, DropdownOptionsOs
    },
    data() {
        return {
            id: this.$route.params.id,
            formName: "orderServiceCreateUpdate",
            orderService: new OrderService(),
            urlCreate: "/api/v1/maintenance/order-service/create",
            urlUpdate: "/api/v1/maintenance/order-service/update",
            urlGetById: "/api/v1/maintenance/order-service/get-by-id",
        };
    },
    created() {
        if (this.id) this.getById();
    },
    computed: {
        ...mapGetters("validation", ["isFormValid"]),
        ...mapGetters("generic", ["event"]),
        status() {
            if (this.orderService.status == 1)
                return { title: "Orçamento", type: "info" };

            if (this.orderService.status == 2)
                return { title: "Aprovada", type: "success" };

            if (this.orderService.status == 3)
                return { title: "Reprovada", type: "danger" };
        },
    },
    methods: {
        ...mapActions("generic", ["postApi", "putApi", "getApi"]),
        ...mapMutations("generic", ["removeLoading", "hideModal", "hideVodal"]),
        ...mapMutations("validation", ["resetValidation", "removeFormDirty"]),
        saveOrderService() {
            if (this.orderService.id) {
                let params = { url: this.urlUpdate, obj: this.orderService };
                this.putApi(params).then((response) => {
                    this.removeLoading(["saveSaveCancel"]);
                });
            } else {
                let params = { url: this.urlCreate, obj: this.orderService };
                this.postApi(params).then((response) => {
                    if (response.success) {
                        this.$router.replace({
                            name: "orderServiceUpdate",
                            params: { id: response.content.id },
                        });
                    }
                    this.removeLoading(["saveSaveCancel"]);
                });
            }
        },
        getById() {
            let params = { url: this.urlGetById, obj: { id: this.id } };
            this.getApi(params).then((response) => {
                this.orderService.update(response.content);
                let self = this;
                setTimeout(function () {
                    self.removeLoading(["panel"]);
                    self.removeFormDirty();
                }, 100);
            });
        },
        create() {
            this.$router.push({
                name: "orderServiceCreate",
            });
            this.removeLoading(["btnOrderServiceCreate"]);
        },
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "saveSaveCancel") {
                    this.saveOrderService();
                }

                if (event.name == "updatedStatusOs") {
                    this.getById();
                }
            },
            deep: true,
        },
    },
};
</script>