<template>
    <div>
        <ViewTemplateWithSalveCancel :panel="panel">
            <div slot="content-main">
                <OrderServiceCreateUpdate />
            </div>
        </ViewTemplateWithSalveCancel>
    </div>
</template>

<script>

import ViewTemplateWithSalveCancel from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithSalveCancel";


import OrderServiceCreateUpdate from '../../../components/modules/maintenance/order-service/OrderServiceCreateUpdate.vue'
import DropdownOptionsOs from '../../../components/modules/maintenance/order-service/DropdownOptionsOs.vue'

import { mapGetters, mapMutations } from "vuex";

export default {
    name: "OrderServiceCreateUpdateView",
    components: {
        ViewTemplateWithSalveCancel,
        OrderServiceCreateUpdate, DropdownOptionsOs
    },
    data() {
        return {
            id: this.$route.params.id,
            panel: {
                module: "Manutenção",
                title: "Ordem de Serviço",
                formName: "orderServiceCreateUpdate",
                showFilter: false,
                showSearch: false,
                showButtons: true,
            },
        };
    },
    computed: {
        ...mapGetters("generic", ["event"]),
    },
    methods: {
        ...mapMutations("generic", ["removeLoading"]),

    },
};
</script>