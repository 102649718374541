<template>
  <div>
    <DisplayTotalization title="TOTAL" :value="totalization" />
  </div>
</template>
<script>
import DisplayTotalization from "@nixweb/nixloc-ui/src/component/rental/DisplayTotalization";

import { mapActions, mapState, mapMutations, mapGetters } from "vuex";

export default {
  name: "OrderServiceTotalization",
  components: { DisplayTotalization },
  props: {
    orderServiceId: String,
  },
  data() {
    return {
      urlGetTotalization: "/api/v1/maintenance/order-service/totalization",
      totalization: 0,
    };
  },
  created() {
    this.getTotalization();
  },
  computed: {
    ...mapState("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    getTotalization() {
      let params = { url: this.urlGetTotalization, obj: { orderServiceId: this.orderServiceId } };
      this.getApi(params).then((response) => {
        this.totalization = response.content;
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "updateTotalizationOrderService")
          this.getTotalization();
      },
      deep: true,
    },
  },
};
</script>
